import Home from "./pages/home";
import Termsconditions from "./pages/terms-condition";
import Privacypolicy from "./pages/privacy-policy";
// import Product from "./pages/products";
// import ProductDetails from "./pages/products-details";

const Routes = [
    {
        path: "/",
        key: "/",
        exact: true,
        element: <Home/>
    },
    // {
    //   path: "/about-us",
    //   key: "/about-us",
    //   exact: true,
    //   element:<Aboutus/>
    // },
    {
        path: "/terms-condition",
        key: "/terms-condition",
        exact: true,
        element: <Termsconditions/>
    },
    {
        path: "/privacy-policy",
        key: "/privacy-policy",
        exact: true,
        element: <Privacypolicy/>
    },
];

export default Routes;
