import React, { useState } from "react";
import styles from "./header.module.scss";
// import 'font-awesome/css/font-awesome.min.css';
import { Link, NavLink,} from "react-router-dom";


function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return ( 
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.headrRow}>
            <div><img src= {"../../images/logo.png"} /></div>
            <div className={`headerMenu ${showMobileMenu ? "mobile-menu" : ""}`}>              
                <ul>
                  <li><NavLink to="/">Home</NavLink></li>
                  {/*<li><NavLink to="/about-us">About Us</NavLink></li>           */}
                  <li><NavLink to="/terms-condition">Term & Conditions</NavLink></li>         
                  <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>         
                  {/* <li><NavLink to="/contact-us">Contact Us</NavLink></li> */}
                </ul>
            </div>
            <i className='fa fa-bars' onClick={() => setShowMobileMenu(x => !x)}></i>            
        </div>
        
      </div>     
       
    </div>
  );
}

export default Header;
