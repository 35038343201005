import React from "react";
import styles from "./footer.module.scss";
import { Link, NavLink } from "react-router-dom";

function Footer({id}) {
  
  return (
    <div className={styles.footerSec} id={id}>
       {/* <div className={styles.footerSubscribePrt}>
        <div className={styles.container}>
          <div className={styles.textCenter}>
            <h2>Subscribe to Our <span>Emails</span></h2>
            <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h3>
            <form>
              <div className={styles.subscribeForm}>
                <input type="text" placeholder="Enter Your Email" />
                <button type="submit">Subscribe</button>
              </div>
            </form>
          </div>
        </div>
      </div> */}

        <div className={styles.footerMenuPrt}>
          <div className={styles.container}>
            <div className={styles.flxRow}>
              <div className={`${styles.footerClm} ${styles.footerLogoClm}`}>
                <div className={styles.footerLogo}>
                  <img src= {"../../images/footer-logo.png"} />
                </div>
                {/* <p>Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod tempor incididunt.</p> */}
                {/* <h4>Our Newsletter</h4>
                <form>
                  <input type="text" placeholder="Enter your email address" name="" />
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                </form> */}
              </div>
              <div className={`${styles.footerClm} ${styles.footerMenuClm}`}>
                <h3>Comapany</h3>
                <ul>
                  <li><NavLink to="/">Home</NavLink></li>
                  {/*<li><NavLink to="/about-us">About Us</NavLink></li>*/}
                  {/* <li><a href="#">Contact Us</a></li> */}
                  <li><NavLink to="/terms-condition">Term & Conditions</NavLink></li>
                  <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                </ul>
              </div>
              <div className={`${styles.footerClm} ${styles.footerContactClm}`}>
                <h3>Contact Us</h3>
                <ul>
                  {/* <li>
                    <img src= {"../../images/location-icon.png"} />
                    <p>4405 Mall Blvd. STE 140 Union City, GA 30291</p>
                  </li>
                  <li>
                    <img src= {"../../images/call-icon.png"} />
                    <p>404.649.0175</p>
                  </li> */}
                  <li>
                    <img src= {"../../images/mail-icon.png"} />
                    <p>info@drywallgenie.com</p>
                  </li>
                  <li>
                  <a style={{width:'60%'}} target="_blank" href="#"><img style={{width:'100%'}} alt="" src= {"../../images/appstore-btn.png"} /></a>                  
                  </li>
                  <li style={{marginTop:'0'}}><a style={{width:'60%'}} target="_blank" href="#"><img style={{width:'100%'}} alt="" src= {"../../images/playstore-btn.png"} /></a></li>
                </ul>
              </div>
              {/* <div className={`${styles.footerClm} ${styles.footerSocialClm}`}>
                <h3>Social Media</h3>
                <ul>
                  <li><a href="#" target="_blank"><i class="fa fa-facebook-square"></i>Facebook</a></li>
                  <li><a href="#" target="_blank"><i class="fa fa-twitter"></i>Twitter</a></li>
                  <li><a href="#" target="_blank"><i class="fa fa-instagram"></i>Instagram</a></li>
                  <li><a href="#" target="_blank"><i class="fa fa-linkedin"></i>Linkedin</a></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>

        <div className={styles.footerCopyrightPrt}>
          <div className={styles.container}>
            <p>Copyright © 2023 Drywall Genie. All Rights Reserved</p>
          </div>
        </div>
  
  </div>
    
  );
}

export default Footer;
