import { BrowserRouter, Route, Routes} from "react-router-dom";
import routes from "./routes";
// import Product from "pages/products";


const App = () => {
  return (
    <BrowserRouter>      
      <Routes>
        {routes.map((routeProps) => {
          return <Route {...routeProps} />;
        })}
         
      </Routes> 
      {/* <Route path="/Product" exact component={Product} />  */}
    </BrowserRouter>
  );
};

export default App;
